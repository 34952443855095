import React from "react"
import { Text } from "theme-ui"

import Part from "../../../components/adventures/short-stories/Part"
import Section from "../../../components/adventures/Section"
import Block from "../../../components/adventures/Block"

export default function Intro() {
  return (
    <Part title="Introduction" time="5–10 minutes">
      <Section type="Context">
        <Block type="Secret Context" title="Intro">
          <Text as="p" variant="body">At the beginning of the adventure, the players have already embarked on a quest to find the location marked on a mysterious treasure map. You should ask the players how their characters know each other. Let each character say why they have set out to find treasure.</Text>
          <Text as="p" variant="body">When you're ready to begin, read the introduction below aloud to the players. At the beginning of the adventure, the players arrive at Mischief Mountain after being caught in The Trickster's trap.</Text>
          <Text as="p" variant="body">The players are trapped in The Trickster’s realm and cannot escape without being freed by them. If they attempt to leave the way they came, they will get lost in the fog and re-emerge at the same place in front of the path.</Text>
        </Block>
        
        <Block type="Exposition" title="The Foggy Forest">
          <Text>As you reach the edge of the forest, fog spills out from the forest floor. You know this is the only way to get to the place marked on the treasure map, so keep walking.</Text>
          <Text>The fog feels cool on your skin, and even makes you tingle a little, as if it touched by magic. After a short walk, you are surprised to find that you have reached the other side of the forest. Rising up in the distance is a majestic mountain with a gleaming tower on its summit. The thing is... you didn't see this mountain before you entered the forest.</Text>
          <Text>A gently-winding path lined by blooming flowers just happened to start in front of you. A small sign at the path entrance says "this way to Treasure Town".</Text>
        </Block>
      </Section>
    </Part>
  )
}
